













import { prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import { getMapCenter, initMap, zoomUp, zoomDown, clearMall, drawMall } from '@/components/map/map';
import gradientCard from '@/components/GradientCard/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
import { getContainer } from '@/components';
import { permissionModule } from '@/store/modules/permission';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
@Component({
  components: {
    spinCard,
    rankFilter,
    rankTable,
    gradientCard,
  },
})


export default class TouristSource extends Vue {
  prefix: string = prefix;
  spinShow = true;
  mallMarker!: any;
  tableWh = 400;
  table: any= [];
  map: any;
  duration = 'xm';
  mall_id = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  dates = this.$store.state.mall.dates;
  boundary = this.$store.state.mall.boundary;
  center = this.$store.state.mall.center;
  mapHeight = '0';
  initZom = 16;
  title = '添加竞品';
  $beaconAction: any;

  get competition(): number {
    return permissionModule.competition;
  }
  mounted(): void {
    this.boundary = this.$store.state.mall.boundary;
    this.center = this.$store.state.mall.center;
    this.mapHeight = `${getContainer()}px`;
    this.map = initMap('map-container', this.initZom, this.center);
    this.modalPageHandler();
  }

  @Watch('$store.state.mall.competedModelObj', { deep: true })
  changeCompetedModelObj(e: any): void {
    if (e.nCount === 0) {
      this.modalPageHandler();
    }
  }

  buriedPointHandler(buriedPoint: string, params?: any): void{
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  modalPageHandler(): void{
    const { boundary, center } = this.$store.state.mall;
    this.boundary = boundary;
    this.center = center;
    if (this.center) {
      const center = getMapCenter(this.center);
      this.map.setCenter(center);
      this.map.panBy(198, 0);
      clearMall(this.mallMarker);
      this.mallMarker = drawMall(this.map, center);
    }
    const text2 = this.competition ? '' : '您暂时没有添加竞品的权限哦～';
    const text = text2 ? text2 : '暂时还没有添加任何竞品哦～';
    this.$Modal.confirm({
      title: this.title,
      content: text,
      cancelText: '取消',
      onOk: async () => {
        if (this.competition === 1) {
          this.buriedPointHandler('competitive_add_click', {
            type: '确认',
          });
          this.$router.push('/manage/competition');
        }
      },
      onCancel: async () => {
        this.buriedPointHandler('competitive_add_click', {
          type: '取消',
        });
      },
    });
  }

  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }
}
